export const ProjectsInputMap = [
  {
    ClassType: "project2",
    Title: "MineCraft NPC Modding Tool",
    LiveLink: "https://kitty-shizz.netlify.app/",
    Content:
      "A React based project which converts plain text documents from the NPC Tool Minecraft to a useable Bedrock Format. 700 users and counting. The tool was commmissioned by Minecraft Modder Kitty_Shizz to streamline the NPC creation process from 3-10 hours per build to less then 20 minutes.",
    GithubLink: "https://github.com/Ryan-Vandeneeckhout/Project-Makeup.git",
    ImageLink: "./assets/images/Endless Store.webp",
    ProjectCodeStyles: `Created Using React / Javascript / HTML and CSS3`,
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },

  {
    ClassType: "project1",
    Title: "Makeup SuperStore Endless Demands",
    LiveLink: "https://endlessdemandsreactsuperstore.netlify.app/",
    Content:
      "A React based project that integrates the use of three Rest API, Google Firestore and music which helps users examine a variety of makeup products. Features a persistant user shopping cart and the ability of users to store specific selections and user data.",
    GithubLink: "https://github.com/Ryan-Vandeneeckhout/EndlessDemands.git",
    ImageLink: "./assets/images/Endless Store.webp",
    ProjectCodeStyles:
      "Created Using React / Javascript / Restful APIs / HTML and CSS3",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },
  {
    ClassType: "project2",
    Title: "The History Quiz React Version",
    LiveLink: "https://historyquizreact.netlify.app/",
    Content:
      "A project made under the auspices of the two day React challenge where a web dev refactors/creates a new react app within two days. This project integrates the use of Reactjs, sounds, Google Firebase Realtime DB and SEO best practices to create a fun and interactive experience to test a user's knowledge of British History!",
    GithubLink: "https://github.com/Ryan-Vandeneeckhout/HistoryQuizReact.git",
    ImageLink: "./assets/images/reactHistory.webp",
    ProjectCodeStyles:
      "Created Using React / Javascript / Firebase Realtime DB / HTML5 and CSS3",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },
  {
    ClassType: "project1",
    Title: "Bite-Sized Traveling APP",
    LiveLink: "https://bite-sized-travelling.netlify.app",
    Content:
      "A project that integrates the use of the Yelp Fusion Rest API along with the Realtime database from Google Firebase in a visually appealing travel planning app along with four group members in a agency style group collaboration effort at Juno College.",
    GithubLink: "https://github.com/Ryan-Vandeneeckhout/Project-4",
    ImageLink:
      "./assets/images/mockup-of-a-macbook-on-a-tidy-desk-3817-el1.webp",
    ProjectCodeStyles:
      "Created Using React / Javascript / CSS3 / FireBase RealTime DB / Restful API",
    ProjectShortContent:
      "Created at Juno College by Ryan Van Den Eeckhout, Sabine Tait, Rachel Holloway, and Justin Vien",
  },
  {
    ClassType: "project2",
    Title: "Endless Demands Makeup SuperStore",
    LiveLink: "https://endlessdemandsstore.netlify.app/",
    Content:
      "A React based project that integrates the use of three Rest API, Google Firestore and music which helps users examine a variety of makeup products from two different APIs integrated seamlessly to display a visually appealing makeup store mockup.",
    GithubLink: "https://github.com/Ryan-Vandeneeckhout/Project-Makeup.git",
    ImageLink: "./assets/images/Endless Store.webp",
    ProjectCodeStyles:
      "Created Using React / Javascript / Restful APIs / HTML and CSS3",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },

  {
    ClassType: "project1",
    Title: "Imperial Glamour",
    LiveLink: "https://projectglamour.netlify.app/index.html",
    Content:
      "A project that integrates the use of a Restful API and Javascript Canvas to display a unique visual experince in a mockup of a makeup store in a pair programmming setting at Juno College.",
    GithubLink:
      "https://github.com/projectGlamour/kristinMcRyanVdeProject2.git",
    ImageLink:
      "./assets/images/macbook-pro-mockup-featuring-a-woman-using-her-computer-2331-el1.webp",
    ProjectCodeStyles:
      "Created Using Jqeury / Javascript  / CSS3 / HTML5 / Restful API",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },
  {
    ClassType: "project2",
    Title: "The History Quiz",
    LiveLink: "https://historyquizjs.netlify.app/",
    Content:
      "A project that integrates the use of Javacript, animated video, sounds and SEO best practices to create a fun and interactive experience to test a user's knowledge of British History!",
    GithubLink:
      "https://github.com/Ryan-Vandeneeckhout/HistoryQuizPlainJS-.git",
    ImageLink: "./assets/images/mac2.webp",
    ProjectCodeStyles: "Created Using Sass / Javascript / Jquery / HTML",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },
  {
    ClassType: "project1",
    Title: "Three-Page PSD Client Conversion",
    LiveLink: "https://exigoancapturedproject.netlify.app/",
    Content:
      "A simple three-page client site convert from PSD to create a client directed blog style website incorporating the elements of responsive design.",
    GithubLink:
      "https://github.com/Ryan-Vandeneeckhout/Captured-Project-One-Repo.git",
    ImageLink:
      "./assets/images/young-man-working-on-his-macbook-pro-laptop-and-iphone-6-mockup.webp",
    ProjectCodeStyles:
      "Created Using Javascript / HTML / CSS3 / Responsive Design",
    ProjectShortContent: "Created by Ryan Van Den Eeckhout",
  },
];
