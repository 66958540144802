import "../../sass/_project.scss";
import { ProjectsInputMap } from "./ProjectsInputMap";
import ProjectsItem from "./ProjectsItem";

const Projects = (props) => {
  return (
    <section ref={props.ProjectsView} className="projectList" id="projectList">
      <div className="projectWrapper">
        <div className="titleHolderH2">
          <h2>Projects</h2>
        </div>
        <div className="projectLists">
          {ProjectsInputMap.map((item, index) => {
            return (
              <ProjectsItem
                key={index}
                Title={item.Title}
                Content={item.Content}
                GithubLink={item.GithubLink}
                ImageLink={item.ImageLink}
                LiveLink={item.LiveLink}
                ClassType={item.ClassType}
                ProjectCodeStyles={item.ProjectCodeStyles}
                ProjectShortContent={item.ProjectShortContent}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
