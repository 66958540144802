import "./About.scss";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AboutPage = (props) => {
  const [setParagraph, setParagraphVisible] = useState(false);
  const [ReadMoreText, setReadMoreText] = useState("Read More");
  const [AboutSectionView, AboutSectionInView] = useInView({
    threshold: 0,
    rootMargin: "100px",
  });
  const ReadMore = () => {
    setParagraphVisible(!setParagraph);

    if (setParagraph === false) {
      setReadMoreText("Close");
    } else {
      setReadMoreText("Read More");
    }
  };

  const SecondPara = `I am always searching for new challenges and opportunities for
  mentorship and growth that allow me to sharpen my Web Development
  Skills and broaden the number of web development centric languages
  within my Repertoire. Previously. I excelled in client-facing and
  challenging data-driven positions where strong problem-solving
  skills are key. I hope to further develop these skills in
  conjunction with improving my understanding of web and analytical
  software to help me write stable, legible, accessibility-focused
  and responsive code within my future workplace.`;

  return (
    <section ref={props.AboutView} id="aboutSection" className="about">
      <div ref={props.AboutSectionRef} className="viewFix">
        <div
          ref={AboutSectionView}
          className={`aboutWrapper${
            AboutSectionInView ? " activeAboutAnimation" : " notActive"
          }`}
        >
          <div className="aboutParagraph">
            <div
              className={`boxTitle${
                AboutSectionInView ? " aboutActiveAnimation" : " notActive"
              }`}
            >
              <img
                src="./assets/images/profile.jpg"
                alt="Ryan Van DenEeckhout"
              />
            </div>
            <div
              className={`paragrapgh${
                AboutSectionInView ? " aboutActiveAnimation" : " notActive"
              }`}
            >
              <h2>Hello Dear Reader and Welcome...</h2>
              <p>
                Ryan Van Den Eeckhout is a front-end web developer currently
                located in Brantford Ontario with a background in Criminology,
                Office Administration and History. Throughout my professional
                roles, I strive to use my strong communication and
                time-management skills to create an efficient and
                accessibility-focused work environment. In particular, I wish to
                create colourful accessibility driven websites that can be
                enjoyed by all. Furthermore, I strive to improve my React skills
                and intergrate TYPEScript and PHP into my stack.
              </p>

              <p className="secondParagraphAbout">{SecondPara}</p>

              <p
                className={`paragrapghReadMore${
                  setParagraph ? " showPara" : " closed"
                }`}
              >
                {SecondPara}
              </p>
              <button onClick={ReadMore}>{ReadMoreText}</button>
              <a
                href="./assets/resume/Ryan Van Den Eeckhout Short PDF.pdf"
                download=""
                role="button"
                className="button"
              >
                <FontAwesomeIcon icon="fa-download" aria-hidden="true" /> view
                resume
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutPage;
