import "../../../sass/_skills.scss";
import { SkillsItemMap } from "./SkillsItemMap";
import { SkillsFontAwesomeIconItemMap } from "./SkillsFontAwesomeIconItemMap";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SkillsSection = (props) => {
  const [SkillItemsView, SkillItemsInView] = useInView({
    threshold: 0,
  });

  return (
    <section id="skills" ref={props.SkillsView} className="skills">
      <div ref={SkillItemsView} className="skillsWrapper">
        <div className="skillsDev">
          <h2>My Toolbox</h2>
          <ul className="skills-icons">
            {SkillsItemMap.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`skillIcons${
                    SkillItemsInView ? " wrap" : " notActive"
                  }`}
                >
                  <span>
                    <i className={item.Icon} />
                    <p>{item.SkillName}</p>
                  </span>
                </li>
              );
            })}
            {SkillsFontAwesomeIconItemMap.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`icon2 skillIcons${
                    SkillItemsInView ? " wrap" : " notActive"
                  }`}
                >
                  <span>
                    <FontAwesomeIcon
                      className="fontAwesomeSkillIcon"
                      icon={item.Icon}
                    />
                    <p>{item.SkillName}</p>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default SkillsSection;
