import "./BottomSiteSectionContainer.scss";
import React from "react";
import BottomOfSiteFrom from "./BottomSiteForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomSiteContactIconsMap } from "./BottomSiteContactIconsMap";

const BottomSiteSectionContainer = (props) => {
  return (
    <section ref={props.ContactView} className="bottomSite" id="bottomSite">
      <div className="bottomSite-Wrapper">
        <div className="contactWrapper">
          <BottomOfSiteFrom />
          <div className="contactMethods">
            <h3>Get in Touch</h3>
            <p>
              Hello there, feel free to contact me regarding my projects, code
              or invite me to any future opportunities that you think could
              benefit from my skillset or input. Thank you, dear viewer, for
              visiting my portfolio site and I hope to hear from you soon about
              any amazing opportunities. Thank you.
            </p>
            <div className="iconlListWrapper">
              {BottomSiteContactIconsMap.map((item, index) => {
                return (
                
                    <a
                      key={index}
                      aria-label={item.IconText}
                      href={item.ContactLink}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="iconsBottom"
                        icon={[
                          `${item.IconPicturePrefix}`,
                          `${item.IconPicture}`,
                        ]}
                      />
                    </a>
  
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="credits">
        <p>Created by Ryan Van Den Eeckhout</p>
      </div>
    </section>
  );
};

export default BottomSiteSectionContainer;
