import React from "react";
import ReactDOM from "react-dom";
import "./sass/_setup.scss";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./components/firebase/context/AuthContext.js";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
