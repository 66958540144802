import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer";

const ProjectsItem = (props) => {
  const [ProjectsItemsView, ProjectsItemsInView] = useInView({
    threshold: 0,
    rootMargin: "100px",
  });

  return (
    <div key={props.index} ref={ProjectsItemsView} className={props.ClassType}>
      <div
        className={`projectsItemWrapper${
          ProjectsItemsInView
            ? " animationActiveProject"
            : " notActiveAnimation"
        }`}
      >
        <div className="projectPara">
          <h3>{props.Title}</h3>
          <h4>
            Project Live <a aria-label="Project Live" href={props.LiveLink}>Link</a>
          </h4>
          <p>{props.Content}</p>
          <h4>
            Github - <a href={props.GithubLink} aria-label="Github Code base Link">View Repo</a>
          </h4>
          <h5>{props.ProjectCodeStyles}</h5>
        </div>
        <div className="imgProjectContainer">
          <a aria-label="Project Live" href={props.LiveLink}>
            <div className="imgProjectContainerWrapper">
              <div className="projectOverView" />
              <div className="projectShortParagraph">
                <h3>{props.Title}</h3>
                <p>{props.ProjectShortContent}</p>
                <FontAwesomeIcon className="iconEye" icon="fa-eye"/>
              </div>
            </div>
          </a>
          <img src={props.ImageLink} alt={props.Title} />
        </div>
      </div>
    </div>
  );
};

export default ProjectsItem;
