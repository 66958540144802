import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const DevMenu = (props) => {
  const [hoverOpen, setHoverOpen] = useState(false);

  const setHoverState = () => {
    setHoverOpen((hoverOpen) => !hoverOpen);
  };

  return (
    <section className="DevMenu">
      <button
        aria-label="Mobile Navigation"
        onClick={props.MobileNavFunction}
        className={`NavMobile${props.mobileNav ? " rotating" : " not"}`}
        onMouseEnter={setHoverState}
        onMouseLeave={setHoverState}
      >
        {props.mobileNav ? (
          <FontAwesomeIcon
            icon={`${hoverOpen ? "fa-times" : "fa-angle-left"}`}
          />
        ) : (
          <FontAwesomeIcon
            icon={`${hoverOpen ? "fa-angle-right" : "fa-bars"}`}
          />
        )}
      </button>
    </section>
  );
};
export default DevMenu;
