import "../../scss/IntroLandingPage.scss";

const Bubbles = () => {
  
  return (
    <div className="wrapper">
        {Array.from({ length: 15 }, (_, index) => <div key={index}><span className="dot" /></div>)}
      </div>
  );
};
export default Bubbles;