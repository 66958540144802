import "./App.scss";
import { useAuthContext } from "./components/firebase/firebaseHooks/useAuthContext.js";
import "./scss/_variables.scss";
import { useState } from "react";
import Navigation from "./components/navigation/Nav";
import IntroLandingPage from "./components/sections/IntroLandingPage.jsx";
import AboutPage from "./components/sections/About";
import BottomSiteSectionContainer from "./components/sections/BottomOfSite/BottomSiteSection";
import SkillsSection from "./components/sections/skills/SkillsSection.jsx";
import Projects from "./components/sections/Projects";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faAngleUp,
  faPlay,
  faHandshakeAlt,
  faMobileAlt,
  faUniversalAccess,
  faPause,
  faAngleDown,
  faMusic,
  faEye,
  faDownload,
  faBuildingColumns,
  faArrowRightFromBracket,
  faDatabase,
  faBug,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import SideMenu from "./components/navigation/side menu/SideMenu";
import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PlayPauseMusicButton from "./components/globalButtons/PlayPauseMusicButton";
import GlobalOverlayModal from "./components/globalOverlay/GlobalOverlayModal";
import ScrollUpButton from "./components/globalButtons/ScrollUpButton";

library.add(
  fab,
  faBars,
  faAngleUp,
  faHandshakeAlt,
  faMobileAlt,
  faUniversalAccess,
  faPause,
  faPlay,
  faTimes,
  faDownload,
  faEye,
  faAngleDown,
  faMusic,
  faBuildingColumns,
  faArrowRightFromBracket,
  faDatabase,
  faBug,
  faAngleRight,
  faAngleLeft
);

function App() {
  const { authIsReady } = useAuthContext();
  const [mobileNav, setMobileNav] = useState(false);
  const [MusicPlaying, setMusicPlaying] = useState(false);
  const AboutSectionRef = useRef();
  const RefList = [AboutSectionRef.current];
  const MobileNavFunction = () => {
    setMobileNav((mobileNav) => !mobileNav);
  };

  const scrolldownArrowFunction = () => {
    AboutSectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };
  const [Navbar, NavBarChange] = useInView({
    threshold: 0,
  });

  const [AboutView, AboutInView] = useInView({
    threshold: 0.5,
  });
  const [SkillsView, SkillsInView] = useInView({
    threshold: 0.4,
  });
  const [ProjectsView, ProjectsInView] = useInView({
    threshold: 0.2,
  });
  const [ContactView, ContactInView] = useInView({
    threshold: 0.4,
  });

  const MusicPlayingTracker = () => {
    setMusicPlaying(!MusicPlaying);
  };
  const MusicPlayingTrackerButton = () => {
    setMusicPlaying(true);
  };

  let htmlElement = document.documentElement;
  htmlElement.setAttribute(
    "data-theme",
    window.localStorage.getItem("dataTheme")
  );
  //Reset theme to defaults if user selects a prefab design
  const ResetTheme = () => {
    if (htmlElement.getAttribute("data-theme") === "custom") {
      //Loop for custom theme from local storage
      let customTheme = "purple";
      themeStateElements(customTheme);
    } else {
      // Change background components to prefab theme
      let customTheme = "";
      themeStateElements(customTheme);
    }
  };

  const themeStateElements = (customTheme) => {
    for (let i in RefList) {
      RefList[i].style.backgroundColor = `${customTheme}`;
    }
    return;
  };

  useEffect(() => {
    const onPageLoad = () => {
      const RefListv = [AboutSectionRef.current, AboutSectionRef.current];
      if (htmlElement.getAttribute("data-theme") === "custom") {
        for (let i in RefListv) {
          RefListv[i].style.backgroundColor = "purple";
        }
      }
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, [htmlElement]);
  return (
    <>
      <Navigation
        AboutInView={AboutInView}
        SkillsInView={SkillsInView}
        ProjectsInView={ProjectsInView}
        ContactInView={ContactInView}
        Nope={NavBarChange}
        mobileNav={mobileNav}
        MobileNavFunction={MobileNavFunction}
      />
      <GlobalOverlayModal
        mobileNav={mobileNav}
        MobileNavFunction={MobileNavFunction}
      />
      <IntroLandingPage
        Lol={Navbar}
        scrolldownArrowFunction={scrolldownArrowFunction}
      />
      <div className="appSideBar">
        <PlayPauseMusicButton
          MusicPlayingTracker={MusicPlayingTracker}
          MusicPlaying={MusicPlaying}
        />
        <ScrollUpButton />
      </div>
      <Projects ProjectsView={ProjectsView} />
      <AboutPage AboutView={AboutView} AboutSectionRef={AboutSectionRef} />
      {/*FireBase Check if User Sign in Wrap for APP*/}
      {authIsReady && (
        <SideMenu
          MusicPlayingTracker={MusicPlayingTracker}
          MusicPlayingTrackerButton={MusicPlayingTrackerButton}
          mobileNav={mobileNav}
          ResetTheme={ResetTheme}
        />
      )}
      <SkillsSection SkillsView={SkillsView} />

      <BottomSiteSectionContainer ContactView={ContactView} />
    </>
  );
}

export default App;
