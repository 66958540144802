import "./Nav.scss";
import DevMenu from "./DevMenu";
import { useState } from "react";

const Navigation = (props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <nav className={`NavBar${props.Nope ? " white" : " black"}`} id="opened">
      <DevMenu
        mobileNav={props.mobileNav}
        MobileNavFunction={props.MobileNavFunction}
        DevMenuRef={props.DevMenuRef}
      />
      <ul className="navBarUpperSite">
        <li>
          <a
            onMouseEnter={() => setIsShown(false)}
            id="aboutNav"
            href="#aboutSection"
            className={`navbarChange${
              props.AboutInView ? " notActive" : " active"
            }`}
          >
            <span>About</span>
          </a>
        </li>
        <li>
          <a
            className={`navbarChange${
              props.SkillsInView ? " notActive" : " active"
            }`}
            onMouseEnter={() => setIsShown(false)}
            id="skillsNav"
            href="#skills"
          >
            <span>Skills</span>
          </a>
        </li>
        <li onMouseEnter={() => setIsShown(true)}>
          <a
            className={`navbarChange${
              props.ProjectsInView ? " notActive" : " active"
            }`}
            id="projectsNav"
            href="#projectList"
          >
            <span>Projects</span>
          </a>
          <ul
            onMouseLeave={() => setIsShown(false)}
            className={`projectsListNav${
              isShown ? " NavOpened" : " NavClosed"
            }`}
          >
            <li href="#projectList">Web Projects</li>
            <li>Written Projects</li>
          </ul>
        </li>
        <li onMouseEnter={() => setIsShown(false)}>
          <a
            className={`navbarChange${
              props.ContactInView ? " notActive" : " active"
            }`}
            id="contactNav"
            href="#bottomSite"
          >
            <span>Contact</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
