export const SkillsItemMap = [

    {
        SkillName: "html",
            Icon: "devicon-html5-plain"
    },
    {
        SkillName: "css3",
            Icon: "devicon-css3-plain"
    },
    {
        SkillName: "Sass",
            Icon: "devicon-sass-original"
    },
    {
        SkillName: "javascript",
            Icon: "devicon-javascript-plain"
    },
    {
        SkillName: "jquery",
            Icon: "devicon-jquery-plain"
    },
    {
        SkillName: "react",
            Icon: "devicon-react-original"
    },
    {
        SkillName: "git",
            Icon: "devicon-git-plain"
    },
    {
        SkillName: "github",
            Icon: "devicon-github-original"
    },
    {
        SkillName: "firebase",
            Icon: "devicon-firebase-plain"
    },
    {
        SkillName: "terminal",
            Icon: "devicon-bash-plain"
    }
    
]