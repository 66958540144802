const GlobalOverlayModal = (props) => {
  return (
    <section
      className={`overlay${props.mobileNav ? " opened" : " closed"}`}
      onClick={props.MobileNavFunction}
    ></section>
  );
};

export default GlobalOverlayModal;
