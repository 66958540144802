export const BottomSiteContactIconsMap = [
  {
    IconText: "Link to Ryan Van Den Eeckhout`s Linkedin Profile",
    ContactLink: "https://www.linkedin.com/in/ryanvandeneeckhout/",
    IconPicturePrefix: "fab",
    IconPicture: "linkedin",
  },
  {
    IconText: "Link to Ryan Van Den Eeckhout`s Github Profile",
    ContactLink: "https://github.com/Ryan-Vandeneeckhout",
    IconPicturePrefix: "fab",
    IconPicture: "github-square",
  },
  {
    IconText: "Link to Ryan Van Den Eeckhout`s Twitter Profile",
    ContactLink: "https://twitter.com/Traitor_Legion",
    IconPicturePrefix: "fab",
    IconPicture: "twitter-square",
  },
];
