export const SkillsFontAwesomeIconItemMap = [
    {
        SkillName: "ally",
            Icon: "fa-universal-access"
    },
    {
        SkillName: "restful api",
            Icon: "fa-database"
    },
    {
        SkillName: "responsive",
            Icon: "fa-mobile-alt"
    },
    {
        SkillName: "debugging",
            Icon: "fa-bug"
    },
    {
        SkillName: "pair programming",
            Icon: "fa-handshake-alt"
    },
    
]