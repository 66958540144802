import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ScrollUpButton = () => {
  //Simple Scroll to Top Button Handler
  const scrollUp = () => {
    window.scrollTo({ top: 10, behavior: "smooth" });
  };

  return (
    <div className="scrollUp" onClick={scrollUp}>
      <FontAwesomeIcon icon="fa-angle-up"/>
    </div>
  );
};
export default ScrollUpButton;