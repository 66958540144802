import "../../scss/IntroLandingPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Bubbles from "./Bubbles";

const IntroLandingPage = (props) => {
  return (
    <section className="introLandingPage">
      <Bubbles />
      <div className="titleContainer" id="titleContainer">
        <div className="boxContainer">
          <h1 className="titleh1">
            Ryan
            <span className="visibilityHidden" aria-hidden="true">
              .
            </span>
            Van Den Eeckhout
          </h1>
          <h2>
            Front-End{" "}
            <span className="visibilityHidden" aria-hidden="true">
              .
            </span>
            <span>Web Developer</span>
          </h2>
        </div>
      </div>
      <div className="bottomHeader">
        <div className="scrolldown">
          <FontAwesomeIcon
            onClick={props.scrolldownArrowFunction}
            className="scrolldownArrow"
            icon="fa-angle-down"
          />
        </div>
      </div>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </section>
  );
};

export default IntroLandingPage;
