export const MusicMenuButtonMap = [
    //Song List Array Values Map//
    {
      MusicMenuButton: "MusicMenuButton",//Class Name
      music: "SB1",//ID
      songName: "Celtic Music",//Song Name
      hex_value: " #8b0000",//Button Colour
      SongListLocation: "./SongsList/medieval.mp3",//Song Map Path
    },
  
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB2",
      songName: "Carry On",
      hex_value: "#FF7034",
      SongListLocation: "./SongsList/song2.mp3",
    },
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB3",
      songName: "Music of Heavan",
      hex_value: " goldenrod",
      SongListLocation: "./SongsList/song3.mp3",
    },
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB4",
      songName: "Hot Like Wow",
      hex_value: "darkgreen",
      SongListLocation: "./SongsList/song4.mp3",
    },
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB5",
      songName: "Suddenly I can See",
      hex_value: "#0165FC",
      SongListLocation: "./SongsList/song5.mp3",
    },
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB6",
      songName: "Warriors of the World",
      hex_value: "#6F00FE",
      SongListLocation: "./SongsList/song6.mp3",
    },
    {
      MusicMenuButton: "MusicMenuButton",
      music: "SB5",
      songName: "Radio Music: ",
      hex_value: "#AD0AFD",
    },
  ];
  